/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { withPrefix } from "gatsby"
import Layout from "../components/layout"
const DocumentWorkflow = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))

  useScript(withPrefix("js/sticky-header.js"))

  useScript(withPrefix("animations/documentworkflows/documentworkflows.js"))

  useScript(
    withPrefix("animations/documentworkflows/documentworkflowslotte.js")
  )
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        url: "https://documentesign.com/document-workflows/",
        name: "Digital Signature Document Workflow | Document eSign",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/document-workflows/#website",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Automate your document workflow and accelerate your business process with Document eSign workflow solution",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/document-workflows/"],
          },
        ],
      },
    ],
  }

  return (
    <>
      <Layout>
        <SEO
          title="Digital Signature Document Workflow | Document eSign"
          description="Automate your document workflow and accelerate your business process with Document eSign workflow solution"
          schemaMarkup={schema}
        />
        <div className="feature-details doc-workflows">
          <div className="intro show-on-scroll ani-in">
            <div className="container position-relative mb-md-n5">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center pt-lg-5">
                  <div className="h4 pt-5 pb-3">CLOUD STORAGE INTEGRATIONS</div>
                  <h1 className="mb-lg-n4">
                    Seamlessly integrate popular cloud storage solutions
                  </h1>
                </div>
                <div className="col-md-6 offset-md-3 mt-md-n5 text-center">
                  {/* <div
                    id="documentworkflows_lottie_container"
                    className="feature-lottie-ani"
                  ></div> */}
                  <div className="feature-lottie-ani">
                    <img
                      className="img-fluid"
                      src={withPrefix("/images/cloud-integration.png")}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-shape d-none d-lg-block">
              <svg
                width="2560px"
                height="100px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="none"
                x="0px"
                y="0px"
                viewBox="0 0 2560 100"
                xmlSpace="preserve"
                enableBackground="new 0 0 2560 100"
              >
                <polygon points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>
          </div>
          <div className="container mt-md-5 pt-md-5">
            <div className="row pt-md-4">
              <div className="col-md-6 offset-md-3 text-center show-on-scroll ani-in">
                <p className="text-light-100 pt-4 pt-md-0">
                  Seamlessly connect the tools you love with document eSign
                  account to popular cloud storage solutions like Dropbox,
                  Google Drive and other out-of-the-box solutions help to
                  streamline your document workflow. Create, send, track, and
                  eSign important documents right from within Gmail, Google
                  Drive, Google Docs, and the Google Chrome browser.
                </p>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  className="btn btn-dark px-md-5 smt-2 mb-2"
                >
                  Get started
                </a>
                <br />
                <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
          <div className="container py-5">
            <div className="card features-bx mb-5">
              <img
                src={withPrefix("images/dw-top-particles.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />

              <div className="card-body px-lg-5 mt-md-n5 py-0">
                <div className="row">
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-users-cog"></i>
                    </div>

                    <div className="h4">Google Drive</div>
                    <p className="text-light-100">
                      Access files from Google Drive to generate documents and
                      collect eSignatures. Convert existing files into Document
                      eSign templates and documents and send them out for
                      eSignatures.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-tasks"></i>
                    </div>

                    <div className="h4">DropBox</div>
                    <p className="text-light-100">
                      Easily connect Document eSign and Dropbox to access all
                      company’s documents. Repurpose, upload, and leverage your
                      top-performing content into formats offered by Document
                      eSign stored within Dropbox.
                    </p>
                  </div>
                  {/* <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-file-alt"></i>
                    </div>

                    <div className="h4">Microsoft One Drive</div>
                    <p className="text-light-100">
                    The Microsoft OneDrive Online integration works both ways – allowing you to share documents between both platforms easily and efficiently. Automate an array of time-consuming tasks and set conditions for which documents will be shared, where they’ll be stored, and when they’ll be sent.

                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-users"></i>
                    </div>

                    <div className="h4">CRM </div>
                    <p className="text-light-100">
                    Creating proposals is an integral part of sales and usually, a laborious task that requires working outside your CRM. Now with the new Document eSign integration, you can save time and continue working right there in the CRM application. 
                    </p>
                  </div> */}
                </div>
              </div>
              {/* <div className="feature-bx-cta text-center show-on-scroll ani-in">
                <Link to="/features/" className="btn btn-outline-dark">
                  Explore more features
                  <i className="fas fa-arrow-right pl-2"></i>
                </Link>
              </div> */}
              {/* <img
                src={withPrefix("images/dw-bottom-particles.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              /> */}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default DocumentWorkflow
